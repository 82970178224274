// action - state management

import {
  DELETE_FINANCIAL_PLAN_DATA,
  GET_CATEGORIES,
  GET_FINANCIAL_PLAN,
  SET_FINANCIAL_PLAN,
  SET_SELECTED_USER,
  GET_GOALS,
  RESET_FINANCIAL_PLAN,
  GET_CASHFLOW,
  GET_PFS_REPORT,
  GET_PORTFOLIO_CURRENT_DATA,
  GET_PLAN,
  GET_OPTIMIZE_DATA,
  GET_CASHFLOW_TABLE_DATA,
  GET_SPLASH_DATA
} from './constant';

export const initialState = {
  selectedUser: null,
  pfsReport: null,
  cashflowTableData: null,
  financialPlanData: null,
  goals: null,
  categories: null,
  cashflow: null,
  portfolioCurrentData: null,
  plan: null,
  optimizeData: null,
  splashData: null,
  financialPlanTotal: null,
  currentTerminalWealth: null,
  dropdowns: {
    WithdrawalLocations: [],
    TaxableAccounts: [],
    TaxableAndSavingsAccounts: [],
    AssociatedAccounts: [],
    PaymentAccounts: [],
    Collaterals: [],
    FundingSources: [],
    LinkedProperties: [],
    InsuranceProviders: [],
    LongTermCares: [],
    DeathLongTermCares: [],
    LinkToLifeRisks: [],
    LifeEvents: [],
    Assets: [],
    Liabilities: [],
    Members: []
  }
};

const numberFormat = (value) => Math.max(+Number(value).toFixed(2), 0);

const subCategoryMap = new Map();
const appendOptions = (options, optionData, _userId, keepInActive) => {
  const { is_active, ...option } = optionData;

  if (keepInActive) {
    option.is_active = is_active;
  }

  const index = options.findIndex((item) => item.value === option.value);

  if (is_active || keepInActive) {
    if (index === -1) {
      options.push(option);
    } else {
      options[index] = option;
    }
  } else if (index > -1) {
    options.splice(index, 1);
  }

  return options;
};

// Util Functions
let traversedData, option, subCategoryKey, subCategoryName, isAsset, isLifeEvent, sum;

function traverseData({ financialPlanTotal, dropdowns, currentTerminalWealth }, userId, categoryId, subCategory, operation) {
  const { id, sub_category_id, section_name, is_active } = subCategory;
  option = { label: section_name, value: id, is_active: is_active === 1 ? true : false };
  subCategoryKey = `${categoryId}.${sub_category_id}`;
  const subCategoryData = subCategoryMap.get(subCategoryKey);
  if (!subCategoryData) return { dropdowns, financialPlanTotal, currentTerminalWealth, valid: false };
  subCategoryName = subCategoryData?.name;
  isAsset = subCategoryData?.asset;
  isLifeEvent = subCategoryData?.name?.startsWith('LifeEvents.');
  sum = 0;

  if (subCategoryData?.totalFields?.length) {
    const totalSum = numberFormat(subCategoryData.totalFields.reduce((acc, field) => acc + (subCategory.data[field] ?? 0), 0));
    const operationSum = numberFormat(subCategoryData.totalFields.reduce((acc, field) => acc + (operation?.[field] ?? 0), 0));

    if (is_active === 1) {
      sum = totalSum;
      if (operation?.is_active === 1) {
        sum -= operationSum;
      }
    } else {
      if (operation?.is_active === 1 || (operation === 'delete' && subCategory?.data?.is_active === 1)) {
        sum -= totalSum;
      }
    }
  }

  // Dropdown Population
  switch (subCategoryName) {
    case 'InvestmentAssets.TaxableAccount':
    case 'Inheritance.TaxableAccount':
      dropdowns.TaxableAccounts = appendOptions(dropdowns.TaxableAccounts, option, userId);
      dropdowns.AssociatedAccounts = appendOptions(dropdowns.AssociatedAccounts, option, userId);
      dropdowns.FundingSources = appendOptions(dropdowns.FundingSources, option, userId);
      dropdowns.TaxableAndSavingsAccounts = appendOptions(dropdowns.TaxableAndSavingsAccounts, option, userId);
      break;
    case 'OutsideAssets.SavingsAccount':
    case 'Inheritance.SavingsAccount':
      dropdowns.TaxableAndSavingsAccounts = appendOptions(dropdowns.TaxableAndSavingsAccounts, option, userId);
      dropdowns.AssociatedAccounts = appendOptions(dropdowns.AssociatedAccounts, option, userId);
      dropdowns.PaymentAccounts = appendOptions(dropdowns.PaymentAccounts, option, userId);
      dropdowns.FundingSources = appendOptions(dropdowns.FundingSources, option, userId);
      break;
    case 'InvestmentAssets.MoneyMarketAccount':
    case 'Inheritance.MoneyMarketAccount':
    case 'OutsideAssets.MoneyMarketAccount':
    case 'OutsideAssets.CheckingAccount':
    case 'Inheritance.CheckingAccount':
      dropdowns.AssociatedAccounts = appendOptions(dropdowns.AssociatedAccounts, option, userId);
      dropdowns.PaymentAccounts = appendOptions(dropdowns.PaymentAccounts, option, userId);
      dropdowns.FundingSources = appendOptions(dropdowns.FundingSources, option, userId);
      break;
    case 'GuaranteedIncome.CorporatePension':
    case 'GuaranteedIncome.SocialSecurity':
      dropdowns.AssociatedAccounts = appendOptions(dropdowns.AssociatedAccounts, option, userId);
      dropdowns.FundingSources = appendOptions(dropdowns.FundingSources, option, userId);
      break;
    case 'CurrentExpenses.Loan':
    case 'CurrentExpenses.LineOfCredit':
      dropdowns.AssociatedAccounts = appendOptions(dropdowns.AssociatedAccounts, option, userId);
      break;
    case 'OutsideAssets.RealProperty':
    case 'Inheritance.RealProperty':
      dropdowns.LinkedProperties = appendOptions(dropdowns.LinkedProperties, option, userId);
      break;
    case 'InvestmentAssets.FixedAnnuity':
    case 'Inheritance.FixedAnnuity':
    case 'InvestmentAssets.VariableAnnuity':
    case 'Inheritance.VariableAnnuity':
    case 'OutsideAssets.Cash':
    case 'Inheritance.Cash':
    case 'GuaranteedIncome.IncomeAnnuity':
    case 'Inheritance.TrustDistribution':
      dropdowns.FundingSources = appendOptions(dropdowns.FundingSources, option, userId);
      break;
    case 'HealthcareExpenses.InsuranceProvider':
      dropdowns.InsuranceProviders = appendOptions(dropdowns.InsuranceProviders, option, userId);
      break;
    case 'HealthcareExpenses.LongTermCare':
      dropdowns.LongTermCares = appendOptions(dropdowns.LongTermCares, option, userId);
      dropdowns.DeathLongTermCares = appendOptions(dropdowns.DeathLongTermCares, option, userId);
      break;
    case 'OutsideAssets.PermanentLifeInsurance':
    case 'Inheritance.PermanentLifeInsurance':
      dropdowns.DeathLongTermCares = appendOptions(dropdowns.DeathLongTermCares, option, userId);
      break;
    case 'LifeEvents.Death':
      dropdowns.LinkToLifeRisks = appendOptions(dropdowns.LinkToLifeRisks, { ...option, data: subCategory.data }, userId);

      // Life Events
      dropdowns.LifeEvents = appendOptions(
        dropdowns.LifeEvents,
        { ...option, categoryId, data: subCategory.data },
        userId,
        operation !== 'delete'
      );
      break;
    case 'LifeEvents.LongTermCare':
    case 'LifeEvents.Disability':
      // Life Events
      dropdowns.LifeEvents = appendOptions(
        dropdowns.LifeEvents,
        { ...option, categoryId, data: subCategory.data },
        userId,
        operation !== 'delete'
      );
      break;
    case 'TerminalWealth.TerminalWealthAmount':
      currentTerminalWealth += sum;
      break;
  }

  // For All
  if (!isLifeEvent) {
    dropdowns.WithdrawalLocations = appendOptions(dropdowns.WithdrawalLocations, option, userId);

    if (isAsset) {
      // Assets
      dropdowns.Collaterals = appendOptions(dropdowns.Collaterals, { ...option, priority: subCategory?.data?.priority ?? 0 }, userId);
      dropdowns.Assets = appendOptions(dropdowns.Assets, { ...option, categoryId }, userId, operation !== 'delete');
    } else {
      // Liabilities
      dropdowns.Liabilities = appendOptions(dropdowns.Liabilities, { ...option, categoryId }, userId, operation !== 'delete');
    }
  }

  if ((is_active === 1 || operation?.is_active === 1 || operation === 'delete') && !isNaN(sum)) {
    financialPlanTotal += sum;
  }
  return {
    dropdowns,
    financialPlanTotal: numberFormat(financialPlanTotal),
    currentTerminalWealth: numberFormat(currentTerminalWealth),
    valid: true
  };
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const financialPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case GET_PLAN:
      return {
        ...state,
        plan: action.payload
      };
    case GET_OPTIMIZE_DATA:
      return {
        ...state,
        optimizeData: action.payload
      };
    case GET_SPLASH_DATA:
      return {
        ...state,
        splashData: action.payload
      };
    case GET_CASHFLOW_TABLE_DATA:
      return {
        ...state,
        cashflowTableData: action.payload
      };
    case GET_GOALS: {
      const members = [];
      const goals = state.goals ? structuredClone(state.goals) : [];
      action.payload?.forEach((goal, idx) => {
        if (state.goals) {
          const index = goals.findIndex((g) => g.user_id === goal.user_id);
          if (index > -1) {
            goals[index] = { ...goals[index], ...goal };
          }
        } else {
          goals.push(goal);
        }
        members.push({ label: goal.name, value: goal.user_id });
        idx === action.payload.length - 1 && members.push({ label: 'Other', value: 142 });
      });

      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          Members: members
        },
        goals: goals
      };
    }
    case SET_FINANCIAL_PLAN:
      return {
        ...state,
        financialPlanData: action.payload
      };
    case GET_PORTFOLIO_CURRENT_DATA:
      return {
        ...state,
        portfolioCurrentData: action.payload
      };
    case GET_CASHFLOW:
      return {
        ...state,
        cashflow: action.payload
      };
    case GET_PFS_REPORT:
      return {
        ...state,
        pfsReport: action.payload
      };
    case GET_FINANCIAL_PLAN: {
      const { data, singleUser, update } = action.payload;
      const financialPlanData = structuredClone(state.financialPlanData);
      let dropdowns = structuredClone(initialState.dropdowns);
      dropdowns.Members = state.dropdowns.Members;
      let financialPlanTotal = {};
      let currentTerminalWealth = 0;
      Object.entries(state.categories ?? {}).forEach(([categoryName, { _id: categoryId, ...subCategories }]) => {
        financialPlanTotal[categoryId] = 0;
        Object.entries(subCategories).forEach(([subCategoryName, { _id: subCategoryId, nebo, asset, priority, fields }]) => {
          !subCategoryMap.has(`${categoryId}.${subCategoryId}`) &&
            subCategoryMap.set(`${categoryId}.${subCategoryId}`, {
              name: `${categoryName}.${subCategoryName}`,
              nebo,
              asset,
              priority,
              totalFields: !fields ? [] : Object.entries(fields).flatMap(([field, { total }]) => (total ? [field] : []))
            });
        });
      });

      // Update Case
      if (update) {
        dropdowns = state.dropdowns;
        financialPlanTotal = state.financialPlanTotal;
        currentTerminalWealth = state.currentTerminalWealth;
        data.forEach((item) => {
          const categoryData = financialPlanData[`${item.category_id}`] ?? {};
          const userData = categoryData[`${item.user_id}`] ?? [];
          const sectionIndex = userData.findIndex((section) => section.id === item.id);
          const sectionItem = {
            id: item.id,
            sub_category_id: item.sub_category_id,
            nebo: item.nebo,
            priority: item.priority,
            is_active: item.is_active,
            section_name: item.section_name,
            ...item.data,
            checked: false
          };

          traversedData = traverseData(
            { financialPlanTotal: financialPlanTotal[`${item.category_id}`], dropdowns, currentTerminalWealth },
            item.user_id,
            item.category_id,
            item,
            userData[sectionIndex]
          );
          dropdowns = traversedData.dropdowns;
          financialPlanTotal[`${item.category_id}`] = traversedData.financialPlanTotal;
          currentTerminalWealth = traversedData.currentTerminalWealth;

          if (sectionIndex !== -1) {
            userData[sectionIndex] = sectionItem;
          } else {
            userData.push(sectionItem);
          }

          categoryData[`${item.user_id}`] = userData;
          financialPlanData[`${item.category_id}`] = categoryData;
        });
      } else {
        const usersData = singleUser ? [data] : data;
        !update &&
          usersData.forEach((user) => {
            Object.entries(user?.financial_plan ?? {}).forEach(([categoryId, subCategories]) => {
              if (!financialPlanData[categoryId]) {
                financialPlanData[categoryId] = {};
              }

              if (!financialPlanTotal[categoryId]) {
                financialPlanTotal[categoryId] = 0;
              }

              financialPlanData[categoryId][user.user_id] = subCategories.flatMap((subCategory) => {
                const { id, sub_category_id, nebo, is_active, section_name, data } = subCategory;
                traversedData = traverseData(
                  { financialPlanTotal: financialPlanTotal[categoryId], dropdowns, currentTerminalWealth },
                  user.user_id,
                  categoryId,
                  subCategory
                );
                if (!traversedData.valid) return [];
                dropdowns = traversedData.dropdowns;
                financialPlanTotal[categoryId] = traversedData.financialPlanTotal;
                currentTerminalWealth = traversedData.currentTerminalWealth;
                return [
                  {
                    id,
                    sub_category_id,
                    nebo,
                    is_active,
                    section_name,
                    ...data,
                    checked: false
                  }
                ];
              });
            });
          });
      }

      return {
        ...state,
        financialPlanData,
        financialPlanTotal,
        currentTerminalWealth,
        dropdowns
      };
    }
    case DELETE_FINANCIAL_PLAN_DATA: {
      const { categoryId, userId, subCategoryId, financialPlanDataId, data } = action.payload;
      let dropdowns = structuredClone(state.dropdowns);
      let financialPlanTotal = structuredClone(state.financialPlanTotal);
      let currentTerminalWealth = structuredClone(state.currentTerminalWealth);
      traversedData = traverseData(
        { financialPlanTotal: financialPlanTotal[categoryId], currentTerminalWealth, dropdowns },
        userId,
        categoryId,
        {
          id: financialPlanDataId,
          sub_category_id: subCategoryId,
          is_active: 0,
          data
        },
        'delete'
      );
      return {
        ...state,
        financialPlanData: {
          ...state.financialPlanData,
          [categoryId]: {
            ...state.financialPlanData[categoryId],
            [userId]: state.financialPlanData?.[categoryId]?.[userId]?.filter((subCategory) => subCategory.id !== financialPlanDataId)
          }
        },
        dropdowns: traversedData.dropdowns,
        financialPlanTotal: {
          ...financialPlanTotal,
          [categoryId]: traversedData.financialPlanTotal
        },
        currentTerminalWealth: traversedData.currentTerminalWealth
      };
    }
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case RESET_FINANCIAL_PLAN: {
      localStorage.removeItem('selectedUser');
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default financialPlanReducer;
